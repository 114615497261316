import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { object } from 'prop-types';

import { dateToString, parseString, langPath } from '@helpers';
import Image from '@components/Image/Image';
import LangContext from '@contexts';
import style from './ArticlePreview.module.scss';
import Arrow from './image/arrow.inline.svg';

const ArticlePreview = ({ node }) => {
  const { data: postData, tags, uid: postPath } = node;
  const { title, description, date, preview } = postData;
  const currentLang = useContext(LangContext);
  const link = `${langPath(currentLang)}/blog/${postPath}`;

  const dataDescripition = parseString(title.richText)
    ?.split('\n')
    ?.slice(0, 5)
    ?.map((line, index) => {
      if (index === 0 && line.split('').length > 100) {
        return line.substring(0, 100) + '...';
      } else {
        return line;
      }
    })
    .join('\n');

  return (
    <Link to={link} className={style.preview}>
      <div className={style.imagePreview}>
        {preview ? (
          <Image image={preview} />
        ) : (
          <div className={style.placeholder}></div>
        )}
      </div>
      <div className={style.textBlock}>
        <h1 className={style.title}>{dataDescripition}</h1>
        <p className={style.text}>{parseString(description.richText)}</p>
        <div className={style.wrapper}>
          <ul className={style.categoryList}>
            {tags.map((item, index) => {
              return (
                <li className={style.tag} key={`${item}${index}`}>
                  <span>{item}</span>
                </li>
              );
            })}
          </ul>
          <time className={style.date}>{dateToString(date)}</time>
        </div>
        <div className={style.arrow}>
          <Arrow />
        </div>
      </div>
    </Link>
  );
};

ArticlePreview.propTypes = {
  node: object,
};

export default ArticlePreview;
